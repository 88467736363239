<template>
    <v-container color="primary">
        <!-- <h2>{{ $t("subscriptionPackages.title") }}</h2>
       <b-button variant="outline-primary" v-b-modal.AddSubscriptionPackageModal>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            {{ $t("subscriptionPackages.add") }}
        </b-button>-->
        <v-btn color="success" class="mr-0" @click="Adddialog = true">
            {{ $t("subscriptionPackages.add") }}
        </v-btn>
        <v-dialog v-model="Adddialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t("subscriptionPackages.add") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="AddSubscriptionForm" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="addItem.packageName" :rules="[rules.required]" required
                                        label="Package Name">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="4" sm="6" md="6">
                                    <v-text-field v-model="addItem.halfYearFees" :rules="[rules.required, rules.noZero]"
                                        required label="Semi-Annual Fees">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="6" md="6">
                                    <v-text-field v-model="addItem.yearFees" required
                                        :rules="[rules.required, rules.noZero]" label="Annual Fees">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="Adddialog = false">
                        Cancel</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="doAddSubscriptionPackage">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-card icon="clipboard-text" title="Subscription Packages" class="px-5 py-10">
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
            </v-card-title>

            <v-data-table dense :headers="fields" :items="items" :search="search" class="elevation-1">
                <template v-slot:[`header.packageName`]="{ header }">
                    <h6><b> {{ header.text }}</b> </h6>
                </template>
                <template v-slot:[`header.halfYearFees`]="{ header }">
                    <h6><b> {{ header.text }}</b> </h6>
                </template>
                <template v-slot:[`header.yearFees`]="{ header }">
                    <h6><b> {{ header.text }}</b> </h6>
                </template>
                <template v-slot:[`header.Reports`]="{ header }">
                    <h6><b> {{ header.text }}</b> </h6>
                </template>
                <template v-slot:[`header.Action`]="{ header }">
                    <h6><b> {{ header.text }}</b> </h6>
                </template>
                <template v-slot:top>
                    <v-dialog v-model="dialog" max-width="800px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Edit Package</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.packageName" :rules="[rules.required]"
                                                    required label="Package Name">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- <v-col
                                                    cols="4"
                                                    sm="6"
                                                    md="6">
                                                    <v-text-field
                                                        v-model="editedItem.quarterFees"
                                                        :rules="[rules.required,rules.noZero]"
                                                        required
                                                        label="Quarter Fees">
                                                    </v-text-field>
                                                </v-col> -->
                                            <v-col cols="4" sm="6" md="6">
                                                <v-text-field v-model="editedItem.halfYearFees"
                                                    :rules="[rules.required, rules.noZero]" required
                                                    label="Semi-Annual Fees">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="6" md="6">
                                                <v-text-field v-model="editedItem.yearFees" required
                                                    :rules="[rules.required, rules.noZero]" label="Annual Fees">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" :disabled="!valid" text @click="validate">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-dialog>

                    <v-dialog v-model="reportDialog" max-width="800px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Edit Package Reports</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="reportsForm" v-model="validReportsForm" lazy-validation>
                                    <v-container>
                                        <v-treeview selectable selected-color="green" :items="reprotsList"
                                            v-model="packageReports" :selection-type="selectionType"
                                            return-object></v-treeview>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeReportDialog">
                                    Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" :disabled="!valid" text @click="saveReports">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </template>


                <template v-slot:[`item.Action`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small class="mr-2" @click="deletePackage(item)" color="red">
                        mdi-delete-empty
                    </v-icon>
                </template>
                <template v-slot:[`item.Reports`]="{ item }">
                    <!-- <v-btn
                min-width="0"
                depressed
                link
                @click = editReports(item) >
                 <mdicon name="clipboard-text"    @click = editReports(item) ></mdicon>
            </v-btn> -->
                    <v-icon small class="mr-2" @click="editReports(item)">
                        mdi-clipboard-text
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary">
                        No Packages
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <div class="text-center">
            <v-snackbar v-model="snackbar" timeout="1000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";

export default {
    mixins: [validationMixin],
    data() {
        return {

            Adddialog: false,
            search: '',
            reprotsList: null,
            selectionType: 'leaf',
            checkbox: true,
            dialog: false,
            reportDialog: false,
            valid: true,
            validReportsForm: true,
            packageReports: [],
            addItem: {
                id: -1,
                packageName: '',
                // quarterFees:-1,
                halfYearFees: 0,
                yearFees: 0
            },
            editedItem: {
                id: -1,
                packageName: '',
                // quarterFees:-1,
                halfYearFees: 0,
                yearFees: 0
            },
            editedIndex: -1,
            editedReport: [],
            editedReportIndex: -1,
            fields: [
                { value: 'packageName', text: this.$t("subscriptionPackages.grid.packageName") },
                // {value: 'quarterFees', text: 'Quarter Subscription Fees' },
                { value: 'halfYearFees', text: 'Half Year Subscriiption Fees' },
                { value: 'yearFees', text: 'Annual Subscription Fees' },
                { value: 'Reports', text: this.$t("subscriptionPackages.grid.Reports") },
                { value: 'Action', text: this.$t("subscriptionPackages.grid.Action") },

            ],
            rules: {
                required: value => !!value || 'Required Field',
                noZero: value => value > 0 || 'Value Must be greater than 0',
            },
            snackbar: false,
            alertMsg: '',
            items: [],
            options: [],

            currentEditedPackage: null
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        reportDialog(val) {
            val || this.closeٌReportDialog()
        },
    },
    methods: {

        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        addReport(id) {
            for (var rpt of this.reprotsList) {
                console.log(Number(rpt.id) + ' and id is: ' + Number(id))
                if (Number(rpt.id) === Number(id)) {
                    this.packageReports.push(Object.assign({}, rpt))
                }
                else if (rpt.children != null && rpt.children.length > 0) {
                    for (var chld of rpt.children) {
                        if (Number(chld.id) == Number(id)) {
                            this.packageReports.push(Object.assign({}, chld))
                        }
                    }
                }
            }
        },
        editReports(item) {

            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            // this.packageReports = item.selectedReports.split(',');

            var selectedRpots = item.selectedReports.split(",");

            for (var rpt of selectedRpots) {

                this.addReport(rpt);
            }

            console.log('this.packageReports..............')
            console.log(this.packageReports)



            // this.editedReportIndex = this.items.indexOf(item)
            // this.editedReport = item.selectedReports;
            this.reportDialog = true
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedIndex = -1
            })
        },
        closeReportDialog() {
            this.reportDialog = false
            this.$nextTick(() => {
                this.editedReportIndex = -1
                this.editedIndex = -1
            })
        },
        doAddSubscriptionPackage() {
            if (this.$refs.AddSubscriptionForm.validate()) {
                this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/SubscriptionPackage`,
                    data: {
                        PackageName: this.addItem.packageName,
                        Id: this.addItem.id,
                        // QuarterFees:this.editedItem.quarterFees,
                        HalfYearFees: this.addItem.halfYearFees,
                        YearFees: this.addItem.yearFees,
                    }
                }).then((response) => {

                    this.Adddialog = false;

                    this.$bvModal.msgBoxOk('Subscription Package added successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.items = response.data;

                });
            }
        },

        validate() {
            if (this.$refs.form.validate()) {
                this.save();
            }
        },

        save() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/SubscriptionPackage`,
                data: {
                    PackageName: this.editedItem.packageName,
                    Id: this.editedItem.id,
                    // QuarterFees:this.editedItem.quarterFees,
                    HalfYearFees: this.editedItem.halfYearFees,
                    YearFees: this.editedItem.yearFees,

                }
            }).then((response) => {
                if (response.data == true) {
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.close()
                    this.alertMsg = "Package updated successfully"
                    this.snackbar = true;
                }
            });

        },

        saveReports() {


            var reports = Array.prototype.map.call(this.packageReports, function (item) { return item.id; }).join(",");
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/SubscriptionPackage/reports`,
                data: {
                    Id: this.editedItem.id,
                    selectedReports: reports,

                }
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.closeReportDialog()
                    this.alertMsg = "Package updated successfully"
                    this.snackbar = true;
                }
            });


        },

        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onReset() {
            this.form = {
                packageName: null,
                packagePeriod: null,
                numberOfbusinessSectors: 0,
                numberOfUsers: 0
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        showAddSubscriptionPackage() {
            if (this.options.length == 0) {
                this.getPackagePeriodOptions();
            }
            this.onReset();
        },
        getPackagePeriodOptions() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/SubscriptionPackage/PeriodOptions`,
            })
                .then((response) => {
                    this.options = response.data;
                });
        },
        addSubscriptionPackage() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/SubscriptionPackage`,
                data: {
                    PackageName: this.form.packageName,
                    PackagePeriod: this.form.packagePeriod,
                    NumberOfBusinessSectors: this.form.numberOfbusinessSectors,
                    NumberOfUsers: this.form.numberOfUsers,
                    IsActive: true
                }
            }).then((response) => {

                this.$nextTick(() => {
                    this.$bvModal.hide('AddSubscriptionPackageModal')
                });

                this.$bvModal.msgBoxOk('Subscription Package added successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.items = response.data;

            });

        },
        deletePackage(item) {
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(value => {
                    if (value == true) {
                        this.$axios({
                            method: 'delete',
                            url: `${this.$baseURL}/SubscriptionPackage`,
                            data: { Id: item.id }
                        }).then((result) => {
                            this.items = result.data
                        });
                    }
                });
        },
        getVariant(item, activeatePackage) {
            if (Boolean(activeatePackage) == true) {
                if (Boolean(item.isActive) == true)
                    return 'primary'
                else
                    return 'secondary'
            }
            else {
                if (Boolean(item.isActive) == true)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        editPackage(item, index) {
            if (this.options.length == 0) {
                this.getPackagePeriodOptions();
            }
            this.$nextTick(() => {
                console.log(item)
                this.form.id = item.id;
                this.form.packageName = item.packageName;
                this.form.packagePeriod =
                    this.options.filter(o => o.name == item.packagePeriodName)[0].id;
                this.form.numberOfbusinessSectors = item.numberOfBusinessSectors;
                this.form.numberOfUsers = item.numberOfUsers;


                this.$bvModal.show('EditSubscriptionPackageModal');
                this.currentEditedSector = index;
            });
        },
        updateSubscriptionPackage() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/SubscriptionPackage`,
                data: {
                    PackageName: this.form.packageName,
                    PackagePeriod: this.form.packagePeriod,
                    NumberOfBusinessSectors: this.form.numberOfbusinessSectors,
                    NumberOfUsers: this.form.numberOfUsers,
                    IsActive: true,
                    Id: this.form.id
                }
            }).then((response) => {
                if (response.data == true) {
                    this.items[this.currentEditedSector] = this.form;
                    this.$bvModal.hide('EditSubscriptionPackageModal');
                    this.$refs.SurveyYearList.refresh();
                }
            });
        },
        ActivatePackage(item, index, state) {
            if (Boolean(state) == true && Boolean(item.isActive) == true) {
                this.$bvModal.msgBoxOk('package is already Active', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                return;
            }
            if (Boolean(state) == false && Boolean(item.isActive) == false) {
                this.$bvModal.msgBoxOk('Package is already InActive', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                return;
            }
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/SubscriptionPackage/PackageStatus`,
                data: {
                    IsActive: state,
                    Id: item.id
                }
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    this.items[index].isActive = state;
                    this.$refs.SubscriptionPackagesList.refresh();
                }
                else {
                    this.$bvModal.msgBoxOk(response.data.message, {
                        title: 'error',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                }
            });

        }

    },
    mounted() {
        this.$axios({
            method: 'get',
            url: `${this.$baseURL}/SubscriptionPackage/reportsList`,
        })
            .then((response) => {
                this.reprotsList = response.data;


                let newReportsList = [];
                this.reprotsList.map((report) => {

                    if (report.parentReportId == null) {
                        newReportsList.push(
                            {
                                id: report.reportId,
                                name: report.reportName,
                                children: []
                            }
                        );
                    }
                    else {
                        // get parent report id
                        var parent = newReportsList.find((rpt) => {
                            return rpt.id === report.parentReportId
                        });
                        parent.children.push({
                            id: report.reportId,
                            name: report.reportName,
                            children: []
                        });

                    }

                })

                this.reprotsList = newReportsList;



            });
        this.$axios({
            method: 'get',
            url: `${this.$baseURL}/SubscriptionPackage`,
        })
            .then((response) => {
                this.items = response.data;

            });

    }
}
</script>

<style></style>